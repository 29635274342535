// ;(function () {
//   emailjs.init('3vR8puMTDOHUvUAdP')
// })()
function keepNumbersAndPlus(str) {
  // Заменяем все символы, кроме цифр и плюса, на пустую строку
  return str.replace(/[^\d+]/g, '')
}

function validateForm(form) {
  const name = form.querySelector('[name="name"]').value
  const interest = form.querySelector('[name="interest"]').value
  const email = form.querySelector('[name="email"]').value
  const phone = form.querySelector('[name="phone"]').value

  if (!validator.isLength(name, { min: 2 })) {
    showNotification('Пожалуйста, введите Ваше имя')
    form.querySelector('[name="name"]').style.border =
      '2px solid rgb(151, 28, 28)'
    return false
  }

  if (!email && !phone) {
    showNotification('Пожалуйста,введите email или номер телефона')
    return false
  }

  if (email && !validator.isEmail(email)) {
    showNotification('Пожалуйста, введите правильный email')
    form.querySelector('[name="email"]').style.border =
      '2px solid rgb(151, 28, 28)'
    return false
  }

  if (phone && !validator.isMobilePhone(keepNumbersAndPlus(phone), 'ru-RU')) {
    showNotification('Пожалуйста, введите правильный номер телефона')
    form.querySelector('[name="phone"]').style.border =
      '2px solid rgb(151, 28, 28)'
    return false
  }

  const recaptchaResponse = grecaptcha.getResponse()
  if (recaptchaResponse.length === 0) {
    showNotification('Пожалуйста, подтвердите, что вы не робот')
    return false
  }

  return true
}

function showNotification(message) {
  const notification = document.getElementById('notification')
  notification.textContent = message
  notification.style.display = 'block'
  setTimeout(function () {
    notification.style.display = 'none'
  }, 7000)
}

function showSuccess(message) {
  const success = document.getElementById('success')
  success.textContent = message
  success.style.display = 'block'
  setTimeout(function () {
    success.style.display = 'none'
  }, 10000)
}

function setupFormValidation(formId, captchaContainerId) {
  const form = document.getElementById(formId)
  const captchaContainer = document.getElementById(captchaContainerId)
  let captchaWidgetId = null
  let isCaptcha = false

  function addCaptcha() {
    if (!captchaWidgetId) {
      captchaWidgetId = grecaptcha.render(captchaContainer, {
        sitekey: '6LdXZP4pAAAAAOIT5ZF1T2ddxDOTiYW7eRi41nqO',
      })
    }
  }

  function removeCaptcha() {
    if (captchaWidgetId !== null) {
      grecaptcha.reset(captchaWidgetId)
      captchaContainer.innerHTML = ''
      captchaWidgetId = null
    }
  }

  form.querySelectorAll('input').forEach(function (input) {
    input.addEventListener('input', function (event) {
      event.target.style.border = '1px solid rgba(255, 255, 255, 0.5)'
      if (!isCaptcha) {
        isCaptcha = true
        addCaptcha()
      }
    })
  })

  form.addEventListener('submit', function (event) {
    ym(97707162, 'reachGoal', 'TARGET_form')
    if (!validateForm(form)) {
      event.preventDefault()
    } else {
      event.preventDefault()

      const chat_id = '-1002229448193'
      const uri = `https://api.telegram.org/bot7399278077:AAGgP5OgLqpkn5ht6tOESsKGv59G-8yN6t4/sendMessage`

      let message = `Данные из формы:  Маркировка рекламы \n
      Имя отправителя: ${this.name.value}\n
      Задача: ${this.interest.value ? this.interest.value : 'не указано'}\n
      Email:  ${this.email.value ? this.email.value : 'не указано'}\n
      Номер телефона:  ${this.phone.value ? this.phone.value : 'не указано'}\n`

      axios
        .post(uri, {
          chat_id: chat_id,
          text: message,
          parse_mode: 'html',
        })
        .then((res) => {
          this.name.value = ''
          this.interest.value = ''
          this.email.value = ''
          this.phone.value = ''
          removeCaptcha()
          showSuccess('Заявка успешно отправлена!')
        })
        .catch((error) => {
          console.warn(error)
        })

      // emailjs
      //   .send('', '', {
      //     name: this.name.value,
      //     interest: this.interest.value,
      //     email: this.email.value ? this.email.value : 'не указано',
      //     phone: this.phone.value ? this.phone.value : 'не указано',
      //     type: this.type.value,
      //   })
      //   .then(
      //     (response) => {
      //       console.log(
      //         'Email sent successfully!',
      //         response.status,
      //         response.text
      //       )
      //     },
      //     (error) => {
      //       console.error('Failed to send email:', error)
      //     }
      //   )
      var modal = document.getElementById('myModal')
      modal.style.display = 'none'
      const submitButton = form.querySelector('button[type="submit"]')
      submitButton.disabled = true
    }
  })
}

setupFormValidation('form', 'formCaptcha')
setupFormValidation('form-modal', 'captcha')
